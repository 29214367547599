import { viewports } from "./settings"

export const {small, mid, large, max} = viewports;

export const respondToMax = (max, obj) => {
    return {
      [`@media (max-width: ${max - 1}px)`]: {
        ...obj
      }
    }
  }
  
  export const respondToMinMax = (min, max, obj) => {
    return {
      [`@media (min-width: ${min}px) and (max-width: ${max - 1}px)`]: {
        ...obj
      }
    }
  }
  
  export const respondTo = (min, obj) => {
    return {
      [`@media (min-width: ${min}px)`]: {
        ...obj
      }
    }
  }

  export const flexAll = (
    flexDirection = "row",
    justifyContent = "flex-start",
    alignItems = "stretch",
    flexWrap = "nowrap"
  ) => {
    return {
      display: "flex",
      flexDirection,
      justifyContent,
      alignItems,
      flexWrap
    }
  }