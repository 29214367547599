import React, { useState, useEffect } from 'react';
import { createUseStyles } from "react-jss";
import * as mixins from '../jss/mixins';
import { NotificationManager } from 'react-notifications';
import { debounce } from '../utilities';
import { getRecaptchaScore } from '../services';
import ReCaptchaV3Wrapper from './ReCaptchaV3Wrapper';

const useStyles = createUseStyles({
    autocompleteContainer: {
        position: "absolute",
        zIndex: 1,
        top: 10,
        left: "50%",
        transform: "translateX(-50%)",
        width: "95%",

        ...mixins.respondTo(mixins.mid, {
            width: 350,
            left: 10,
            transform: "translateX(0)"
        })
    },
    inputContainer: {
        width: "100%",
        height: 40,
        background: "#fff",
        padding: [10, 20],
        boxSizing: "border-box",
        borderRadius: 10,
        boxShadow: "0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        overflow: "hidden",

    },
    input: {
        width: "100%",
        height: "100%",
        border: "none",
        "&:focus": {
            outline: "none"
        }
    },
    predictionsContainer: {
        width: "100%",
        background: "#fff",
        padding: [10,0],
        marginTop: 10,
        boxSizing: "border-box",
        borderRadius: 10,
        boxShadow: "0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        "& ul": {
            listStyleType: "none",
            marginBlockStart: 0,
            marginBlockEnd: 0,
            paddingInlineStart: 0,

            "& li": {
                padding: [2,10],
                cursor: "pointer",
                "& span": {
                    display: "block",
                    fontSize: 12,
                    color: "#7C7D7E",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    "&:first-child": {
                        fontSize: 13,
                        fontWeight: 600,
                        color: "#000"
                    }
                },
                "&:hover": {
                    background: "#E3E4E7"
                }
            }
        },
    }
})

const AutocompleteNoWidget = (props) => {

    const { google, map, handleMarkerOptions, protectedWithCaptcha } = props;
    let inputRef = null;
    const classes = useStyles();

    const [sessionToken, setSessionToken] = useState(null);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [placesService, setPlacesService] = useState(null);
    const [predictions, setPredictions] = useState(null);

     useEffect(() => {
         //Loading autocomplete and places stuff
         if (map) {
            console.log("stuff created");
            setSessionToken(new google.maps.places.AutocompleteSessionToken());
            setAutocompleteService(new google.maps.places.AutocompleteService());
            setPlacesService(new google.maps.places.PlacesService(map));
         }
     }, [map]);


    const handleInputChange = debounce((value) => {
        if (!value) {
            setPredictions(null);
            return;
        }
        autocompleteService.getPlacePredictions({
            input: value,
            componentRestrictions: {country: 'mx'},
            sessionToken
        },(pre) => setPredictions(pre));
    }, 150);

    const handleSelectPlace = (placeId, description) => {
        //recaptchaStuff
        if(protectedWithCaptcha) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT_KEY, {action: 'place_changed'}).then(token => {
                        getRecaptchaScore(token).then(result => {
                            console.log(result);
                            if (result) {
                                placesService.getDetails({
                                    fields: ['geometry'],
                                    placeId,
                                    sessionToken
                                }, (place,status) => onPlaceSelected(place,status));
                                inputRef.value = description;
                            } else {
                                console.log("Recaptcha inválido")
                            }
                        }).catch(() => {
                            console.log("Error al verificar token recaptcha")
                        })
                        
                    });
                });
            return;
        }

        placesService.getDetails({
            fields: ['geometry'],
            placeId,
            sessionToken
        }, (place,status) => onPlaceSelected(place,status));
        inputRef.value = description;

    }

    const onPlaceSelected = (place, status) => {
        const position = { location: place.geometry.location }
        if (place.geometry.viewport) {
            position.viewport = place.geometry.viewport;
        }
        handleMarkerOptions({
            position,
            visible: true
        });
        setPredictions(null);
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
    }

    return (
        <div className={classes.autocompleteContainer}>
            <div className={classes.inputContainer}>
                <input
                    placeholder="Buscar"
                    type="text"
                    className= {classes.input}
                    onChange={(event) => handleInputChange(event.target.value) } 
                    ref={ref => inputRef = ref}
                />
            </div>
            {predictions && 
                <div className={classes.predictionsContainer}>
                    <ul>
                        {predictions.map((prediction,index) => (
                            <li 
                                key={index}
                                onClick={() => handleSelectPlace(prediction.place_id, prediction.description)}
                            >
                                <span>{prediction.structured_formatting.main_text}</span>
                                {" "}
                                <span>{prediction.structured_formatting.secondary_text}</span>
                            </li>
                        ))}
                    </ul>   
                </div> 
            }
            {protectedWithCaptcha &&
                <ReCaptchaV3Wrapper
                    clientKey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                />
            }
        </div>
    )
};

export default AutocompleteNoWidget;