import React from 'react';

const Header = () => (
    <div className="Header">
        <div className="Header-container">
            <div className="Header-content">

            </div>
        </div>
    </div>
);
export default Header;