import React, { useState, useEffect } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Autocomplete from './Autocomplete';
import { getPlaces, getLocationWorkaround } from '../services';
import skyIcon from '../assets/logo.png';
import BlackboardList from './BlackboardList';
import MarkerWrapper from './MarkerWrapper';
import MarkerClustererWrapper from './MarkerClustererWrapper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PlacesList from './PlacesList';
import loaderIcon from '../assets/loader.svg';
import { createUseStyles } from "react-jss";
import { distanceBetweenTwoPoints } from '../utilities';
import AutocompleteNoWidget from './AutocompleteNoWidget';

const useStyles = createUseStyles({
    loaderHolder: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,255,255,0.6)",
    },
    map: {
        height: "calc(100% - 35px)!important"
    }
});


const MapWrapper = (props) => {

    const [markerOptions, setMarkerOptions] = useState({ position: null, visible: false });
    const [places, setPlaces] = useState([]);
    const [loader, setLoader] = useState(false);
    const classes = useStyles();
    const ttl = 604800000;

    useEffect(() => {
        const geoOptions = { 
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0 
            }

        navigator.geolocation.getCurrentPosition(({coords}) => {
            setMarkerOptions({
                position: { location: new props.google.maps.LatLng(coords.latitude,coords.longitude) },
                visible: true
            });
        }, 
        (error) => {
            if (error.code !== 1) {
                getLocationWorkaround().then(({location}) => {
                    setMarkerOptions({
                        position: { location: new props.google.maps.LatLng(location.lat, location.lng) },
                        visible: true
                    });
                }).catch((error) => {
                    console.log("Error al obtener ubicación")
                });
            }
        },
        geoOptions
        )
    },[]);

    useEffect(() => {
        if (markerOptions.position) {
            setLoader(true);
            const latLng = {lat: markerOptions.position.location.lat() , lng: markerOptions.position.location.lng()};
            const expires  = new Date().getTime() + ttl;

            if (!localStorage.getItem('positionInfo')) {
                localStorage.setItem('positionInfo', JSON.stringify({ expires, latLng }));
            } else {
                const lSPositionInfo = JSON.parse(localStorage.getItem('positionInfo'));
                if (distanceBetweenTwoPoints(lSPositionInfo.latLng, latLng) < 1 && new Date().getTime() < lSPositionInfo.expires ) {
                    const lSPlaces = JSON.parse(localStorage.getItem('places'));
                    lSPlaces.length ? setPlaces(lSPlaces) : 
                                    NotificationManager.error('No se encontraron lugares cercanos', '', 5000);
                    setLoader(false);
                    return;
                }
                localStorage.setItem('positionInfo', JSON.stringify({ expires, latLng }));
            }
            
            getPlaces(latLng).then(({filtros, ubicaciones}) => {
                console.log(ubicaciones);
                const responseParced = ubicaciones.map(
                                (item, i) => ({  'latLng': {'lat': item.coordenada['_latitude'], 'lng': item.coordenada['_longitude']},
                                            'nombre': item.nombre || 'Sin información',
                                            'dir': item.dir ||'Sin información',
                                            'liga':  i % 2 == 0 ? 'premierLeague' : 'ligaSantander'
                                        })
                                )

                setPlaces(responseParced);
                localStorage.setItem('places', JSON.stringify(responseParced));
                setLoader(false);
                !ubicaciones.length && NotificationManager.error('No se encontraron lugares cercanos', '', 5000);
            })
            
        }
    },[markerOptions]);

    return (
        <>
            <Map
                google={props.google}
                zoom={10}
                initialCenter={{ lat: 19.4326077, lng: -99.13320799999997 }}
                streetViewControl={false}
                mapTypeControl={false}
                fullscreenControl={false}
                zoomControlOptions={{position: props.google.maps.ControlPosition.RIGHT_CENTER}}
                className={classes.map}
            >
                {/* <Autocomplete handleMarkerOptions={setMarkerOptions} /> */}
                <AutocompleteNoWidget 
                    handleMarkerOptions={setMarkerOptions} 
                    protectedWithCaptcha={true}
                />

                {markerOptions.position &&
                    <MarkerWrapper {...markerOptions} />
                }

                {/* {places.length &&
                    places.map((place, index) => (
                        <Marker
                            key={index}
                            position={place.latLng}
                            icon={{ url: skyIcon, scaledSize: new props.google.maps.Size(62, 38) }}
                        />
                    ))
                } */}

                {places.length &&
                    <MarkerClustererWrapper 
                        places={places}
                        icon={{ url: skyIcon, scaledSize: new props.google.maps.Size(62, 38) }}
                    />
                }

                {places.length && 
                    <PlacesList 
                        originalPlace={markerOptions.position.location}
                        places={places}
                        handlePlaces={setPlaces}
                    />
                }
            </Map>
            {loader &&
                <div className={classes.loaderHolder}>
                    <img src={loaderIcon}/>
                </div>
            }
            <NotificationContainer/>
        </>
    )
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAPS_KEY,
    libraries: ['places'],
    language: 'es'
})(MapWrapper);
