import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import * as mixins from '../jss/mixins';
import questionIcon from '../assets/question.png';
import Modal from './Modal'

const useStyles = createUseStyles({
    footerContainer: {
        position: "fixed",
        bottom: 0,
        width: "100%",
        height: 35,
        zIndex: 1,
        background: "rgb(33, 150, 243)"
    },
    footerContent: {
        width: "100%",
        height: "100%",
        ...mixins.flexAll("row", "center", "center"),
        "& span": {
            fontWeight: 600,
            color: "rgb(51,51,51)"
        },
        "&>img": {
            height: "70%",
            margin:[0, 20],
            cursor: "pointer"
        }
    }
})

const Footer = () =>  {
    const classes = useStyles();
    const  [modalVisible, setModalVisible] = useState(false);
    return (
        <>
            <div className={classes.footerContainer}>
                <div className={classes.footerContent}>
                    <span>Versión 1.0</span>
                    <img 
                        src={questionIcon}
                        onClick={ () => setModalVisible(!modalVisible)}
                    />
                </div>
            </div>
            <Modal
                visible={modalVisible}
                handleVisible={setModalVisible}
                headerText={"Información"}
                bodyText={"Si no quiere que su negocio aparezca en la aplicación, envíe un correo a skyinteractive@sky.com.mx"}
                // options={[{text: "Si", background:"red", color:"white", func:() => { console.log('test')}},{text: "No"}]}
            />
        </>
    )
}
export default Footer;