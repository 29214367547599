import React from 'react';
import { Marker } from 'google-maps-react';

const MarkerWrapper = (props) => {
    const {position, map, ...remaining} = props;

    if (position.vierport) {
        map.fitBounds(position.viewport); 
    } else {
        map.setCenter(position.location);
        map.setZoom(17);
    }
    
    return (
        <Marker 
            {...remaining}
            map = {map}
            position = {position.location}
        />
    );

}

export default MarkerWrapper;