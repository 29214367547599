import React, { useEffect } from 'react';
import MarkerClusterer from '@google/markerclustererplus';
import { Marker } from 'google-maps-react';

const MarkerClustererWrapper = (props) => {
    const { map, google, places, icon } = props;

    useEffect(() => {
        let markers = places.map((place) => {
            return new google.maps.Marker({ position: place.latLng, icon });
        });
        const mc = new MarkerClusterer(map, markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
        
        //cleanup
        return () => { mc.clearMarkers(); }
    },[places]);

    return (null);
}

export default MarkerClustererWrapper;