import { createUseStyles } from "react-jss";

    const mainStyles = createUseStyles({
        main: {
            width: "100%",
            height: "100%"
        }

    })

export default mainStyles;

