import React, { useEffect } from 'react';

const ReCaptchaV3Wrapper = ({ clientKey }) => {

    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${clientKey}`
        document.body.appendChild(script);

    }, []);
    return (null);
}
 
export default ReCaptchaV3Wrapper;