const distanceBetweenTwoPoints =  (P1, P2) => {
    const R = 6371; // km
    const X1 = toRadians(P1.lat);
    const X2 = toRadians(P2.lat);
    const EX = toRadians((P2.lat - P1.lat));
    const EL = toRadians((P2.lng - P1.lng));
    const a = Math.sin(EX / 2) * Math.sin(EX / 2) + Math.cos(X1) * Math.cos(X2) * Math.sin(EL / 2) * Math.sin(EL / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

function toRadians(deg) {
  return deg * (Math.PI/180)
}

  
function debounce(func, wait, immediate = false) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

  export { distanceBetweenTwoPoints, debounce };