import React, { useState, useEffect } from 'react';
import BlackboardList from './BlackboardList';
import { getPlaces } from '../services';
import { createUseStyles } from "react-jss";
import expandIcon from '../assets/expand-blue.png';
import collapseIcon from '../assets/collapse-blue.png';
import * as mixins from '../jss/mixins';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import jmespath from 'jmespath';
import premierIcon from '../assets/premier_league.png';
import laLigaIcon from '../assets/laliga_santander.png';
import ligaMxIcon from '../assets/liga_bbva.png';
import qualifiers from '../assets/qualifiers.png';
import arrowTop from '../assets/arrow-top.png';



const useStyles = createUseStyles({
    placesContainer: {
        position: "absolute",
        left: 0,
        width: "100%",
        height: "50%",
        background: "white",
        padding: [5, 10],
        boxSizing: "border-box",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        boxShadow: "0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        transition: "0.5s",
        ...mixins.flexAll("column", "flex-start", "flex-start"),
        "&.opened": {
            top: "50%"
        },
        "&.closed": {
            top: "100%"
        },
        ...mixins.respondTo(mixins.large, {
            position: "absolute",
            width: 400,
            height: "100%",
            paddingTop: 60,
            borderRadius: 0,

            "&.opened": {
                top: 0,
                left: 0
            },
            "&.closed": {
                top: 0,
                left: -400
            }
        })
    },
    placesInner: {
        width: "100%",
        ...mixins.flexAll("column", "center", "center"),
        flex: "1 1 0", 
        "& .scrollbar-container": {
            width: "100%",
            height: "auto!important",
            flex: "1 1 0"
        }
    },
    placeContainer: {
        borderBottom: "solid 1px #ccc",
        "&:last-of-type": {
            border: "none"
        }
    },
    placeInner: {
        ...mixins.flexAll("row", "flex-start", "center"),
        padding: [5, 0]
    },
    toggleExpand: {
        width: 20,
        height: 20,
        display: "inline-block",
        background: `url(${expandIcon})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer"
    },
    toggleCollapse: {
        width: 20,
        height: 20,
        display: "inline-block",
        background: `url(${collapseIcon})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer"
    },
    placeInfo: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 10,
    },
    placeName: {
        fontWeight: 600,
        fontSize: 14
    },
    placeDir: {
        marginLeft: 3,
        fontSize: 10
    }, 
    filterTitle: {
        margin: 5,
        "& span": {
            fontSize: 15,
            fontWeight: 600
        }
    },
    filterContainer: {
        ...mixins.flexAll("row", "flex-start", "center", "wrap"),
        margin: [10, 15]
    },
    option: {
        ...mixins.flexAll("row", "center", "center"),
        margin: "0 5px 10px 0",
        background: "rgba(112,210,232,0.1)",
        borderRadius: 30,
        padding: "2px 10px",
        width: 90, 
        cursor: "pointer",
        "& img": {
            height: 26
        }
    },
    optChecked: {
        background: "rgba(112,210,232,1)"
    },
    noInfo: {
        textAlign: "center"
    },
    toggleContainer: {
        position: "absolute",
        left: "50%",
        top: -20,
        transform: "translate(-50%, 0)",
        width: 40,
        height: 20,
        ...mixins.flexAll("row", "center", "center"),
        background: "#fff",
        boxShadow: "0 -2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        borderRadius: "5px 5px 0 0",
        cursor: "pointer",
        "& img": {
            width: "50%",
            transition: "0.5s"
        },
        "&.opened": {
            "& img": {
                transform: "rotate(180deg)",
            },
        },
        "&.closed": {
            "& img": {
                transform: "rotate(0deg)",
            },
        },
        ...mixins.respondTo(mixins.large, {
            width: 20,
            height: 40,
            left: "auto",
            right: -20,
            top: "50%",
            transform: "translate(0, -50%)",
            borderRadius: "0 5px 5px 0",
            "& img": {
                width: "100%",
            },
            "&.opened": {
                "& img": {
                    transform: "rotate(-90deg)!important",
                },
            },
            "&.closed": {
                "& img": {
                    transform: "rotate(90deg)!important",
                },
            }, 
        })
    }
})

const PlacesList = (props) => {
    const { originalPlace, places, handlePlaces, map } = props;
    const [displayBlackboard, setDisplayBlackboard] = useState(Array(places.length).fill(false));
    const [displayContainer, setDisplayContainer] = useState(true);
    const [filters] = useState(["premierLeague", "ligaSantander"]);
    const [isChecked, setIsChecked] = useState(Array(filters.length).fill(true)) 
    const [originalPlaces, setOriginalPlaces] = useState([]);
    const classes = useStyles();

    const handleBlackboard = (index, latLng) => {
        displayBlackboard[index] = !displayBlackboard[index];
        setDisplayBlackboard( [...displayBlackboard]);

        if (displayBlackboard[index]) {
            map.setCenter(latLng);
            map.setZoom(22);
        } else {
            map.setCenter(originalPlace);
            map.setZoom(17);
        }

    }

    useEffect(() => {
        setOriginalPlaces(places);
        // filters.length = 0;
        return () => { handlePlaces([])}
    },[originalPlace]);

    const handleFilter = (filter, idx) => {
        isChecked[idx] = !isChecked[idx];
        const exist = filters.indexOf(filter);
        if (exist === -1) {
            filters.push(filter);
        } else {
            filters.splice(exist, 1);
        }

        if(filters.length == 0) {
            handlePlaces([""]);
            return;
        }
            
        let  expression = "places[?";
        filters.forEach((filter, index) => {
            expression += `liga=='${filter}'`;
            if (filters.length > 1 && index !== filters.length-1)
                expression += " || "
            if (index === filters.length-1) 
                expression += "]"
        })
        handlePlaces(jmespath.search({"places": originalPlaces}, expression));

    }
    

    return(
        <div className={`${classes.placesContainer} ${displayContainer ? "opened" : "closed"}`}>
            <div 
                className={`${classes.toggleContainer} ${displayContainer ? "opened" : "closed"}`}
                onClick={() => setDisplayContainer(!displayContainer) }
            >
                <img src={arrowTop}/>
            </div>
            <div className={classes.filterTitle}><span>Filtrar por liga:</span></div>
            <div className={classes.filterContainer}>
                <div 
                    className={`${classes.option} ${isChecked[0] ? classes.optChecked : ""}`}
                    onClick={() => handleFilter("premierLeague", 0)}
                >
                    <img src={premierIcon} />
                </div>
                <div 
                    className={`${classes.option} ${isChecked[1] ? classes.optChecked : ""}`}
                    onClick={() => handleFilter("ligaSantander", 1)}
                >
                    <img src={laLigaIcon} />
                </div>
                
                {/* <div className={classes.option}>
                    <input type="checkbox" value="ligaBBVA" onChange={handleFilter}/>
                    <img src={ligaMxIcon} className={classes.icon}/>
                </div>
                <div className={classes.option}>
                    <input type="checkbox" value="europeanQualifiers" onChange={handleFilter}/>
                    <img src={qualifiers} className={classes.icon}/>
                </div> */}
            </div>
            <div className={classes.placesInner}>
                <PerfectScrollbar>
                    {places.map(
                        ({
                            latLng,
                            nombre,
                            dir
                        }, 
                        index
                        ) => {
                            return (
                                latLng ? (
                                    <div className={classes.placeContainer} key={index}>
                                        <div className={classes.placeInner}>
                                            <span 
                                                className={displayBlackboard[index] ? classes.toggleCollapse : classes.toggleExpand }
                                                onClick = {() =>  handleBlackboard(index, latLng)}
                                            />
                                            <div className={classes.placeInfo}>
                                                <span className={classes.placeName}>{nombre}</span>
                                                <span className={classes.placeDir}>{dir}</span>
                                            </div>
                                        </div>
                                        {displayBlackboard[index] && (
                                            <BlackboardList id={index} key={index}/>
                                        )}
                                    </div>
                                ):(
                                    <div className={classes.noInfo}>Sin información</div>
                                )
                            )
                        }
                    )}
                </PerfectScrollbar>
            </div>           
        </div>
        
    )
}

export default PlacesList;