import React from 'react';
import { createUseStyles } from "react-jss";
import skyIcon from '../assets/sky.png';
import closeIcon from '../assets/close_icon.png'
import * as mixins from '../jss/mixins';

const useStyles = createUseStyles({

    modal: {
        position: "fixed",
        width: 300,
        background: "#fff",
        left: "50%",
        transform: "translateX(-50%)",
        borderRadius: 5,
        overflow:"hidden",
        boxShadow: "0 -2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        transition: "0.5s",
        color: "rgb(51,51,51)",
        "&.visible": {
            bottom: 35,
        },
        "&.hidden": {
            bottom: "-100%"
        }

    },
    modalHeader: {
        position: "relative",
        textAlign: "center",
        background: "rgb(33, 150, 243)",
        fontWeight: 600,
        minHeight: 21,
        "& img": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 7,
            width: 15,
            cursor: "pointer"
        }
    },
    modalBody: {
        padding: 10,
        textAlign: "justify"

    },
    modalFooter: {
        textAlign:"center"
    },
    optionsContainer: {
        marginTop: 10,
        ...mixins.flexAll("row", "space-around", "center"),
        "& button": {
            border: "none",
            borderRadius: 3,
            textAlign: "center", 
            minWidth: 60,
            padding: 2,
            fontWeight: 600,
            "&:hover": {
                background: "rgb(33, 150, 243)"
            }
        }
    }

})

const Modal = (props) => {
    const {visible, handleVisible, headerText, bodyText, footerText, options} = props
    const classes = useStyles();

    return (  
        <div className={`${classes.modal} ${visible ? "visible" : "hidden"}`}>
            <div className={classes.modalHeader}>
                <span>{headerText}</span>
                <img 
                    src={closeIcon}
                    onClick={() => handleVisible(!visible)}
                />
            </div>
            <div className={classes.modalBody}>
                <span>{bodyText}</span>
                <div className={classes.optionsContainer}>
                    { options && options.map( ({text, background, color,func}) => 
                        <button 
                            onClick={func}
                            style={{background, color}}
                        >
                            {text}
                        </button>
                    ) }
                </div>
            </div>
            <div className={classes.modalFooter}>
                {footerText ? footerText : <img src={skyIcon}/>}
                
            </div>
        </div>
    )
}
 
export default Modal;