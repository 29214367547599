import React from 'react';

const NotFound = () =>(
    <div className="NotFound">
        <div className="NotFound-container">
            <div className="NotFound-content">
                <h3>Algo salio mal</h3>
            </div>
        </div>
    </div>
);

export default NotFound;