const getPlaces = (latLng) => {
    const requestOptions = { method: "GET", headers: {} };
    return fetch(
        `https://us-central1-baresyrestaurantes-40c39.cloudfunctions.net/getByR?lat=${latLng.lat}&lng=${latLng.lng}`,
        requestOptions
    ).then(response => {
        if ([401, 403].includes(response.status)) {
            return Promise.reject(response.error);
        }
        return response.json();
    })
}

const getEvents =  (id) => {
    const requestOptions = { method: "GET", headers: {} };
    return fetch(
        `http://172.16.52.12:3000/events${id}.json`,
        requestOptions
    ).then(response => {
        if ([401, 403].includes(response.status)) {
            return Promise.reject(response.error);
        }
        return response.json();
    })
}

const getLocationWorkaround = () => {
    return fetch("https://location.services.mozilla.com/v1/geolocate?key=test")
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                return response.json();
            }
            return Promise.reject(response)
            
        })
        .catch(errors => {
            return Promise.reject(errors);
        })
}

const getRecaptchaScore = (tokenResponse) => {
    return fetch(`https://us-central1-baresyrestaurantes-40c39.cloudfunctions.net/getScore?response=${tokenResponse}`)
    .then(response => {
        return response.json();
    })
    .catch(error => {
        return Promise.reject();
    })
}

export { getPlaces, getEvents, getLocationWorkaround, getRecaptchaScore };