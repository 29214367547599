import React from 'react';
import MapWrapper from '../components/MapWrapper';
import mainStyles from '../jss/components-jss/mainStyle';

const Main = () => { 
    const classes = mainStyles();
  
    return (
        <div className={classes.main}>
            <div className="Main-container">
                <div className="Main-content">
                    <MapWrapper/>
                </div>
            </div>
        </div>  
  )
};
 
export default Main;