import React, { useEffect } from 'react';
import { createUseStyles } from "react-jss";
import * as mixins from '../jss/mixins';

const useStyles = createUseStyles({
    inputContainer: {
        position: "absolute",
        zIndex: 1,
        top: 10,
        left: "50%",
        transform: "translateX(-50%)",
        width: "95%",
        height: 40,
        background: "white",
        padding: [10, 20],
        boxSizing: "border-box",
        borderRadius: 10,
        boxShadow: "0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        overflow: "hidden",

        ...mixins.respondTo(mixins.mid, {
            width: 350,
            left: 10,
            transform: "translateX(0)"
        })

    },
    input: {
        width: "100%",
        height: "100%",
        border: "none",
        "&:focus": {
            outline: "none"
        }
    }
})

const Autocomplete = (props) => {

    const { google, map, handleMarkerOptions } = props;
    let inputRef = null;
    const classes = useStyles();
    
    useEffect(() => {
        if (map !== undefined) {
            loadAutocomplete();
        }
    },[map]);

    const loadAutocomplete= () => {
        const options = { componentRestrictions: {country: 'mx'} };
        const autocomplete = new google.maps.places.Autocomplete(inputRef, options);
        autocomplete.bindTo('bounds', map);
        autocomplete.setFields(['geometry']);
        
        autocomplete.addListener('place_changed', () => {

            const place = autocomplete.getPlace();
            console.log(place.geometry.viewport);


            const position = { location: place.geometry.location }

            if (place.geometry.viewport) {
                position.viewport = place.geometry.viewport;
            }


            handleMarkerOptions({
                position,
                visible: true
            });

        });
    }

    return (
        <div className={classes.inputContainer}>
            <input
                placeholder="Buscar"
                type="text"
                className= {classes.input}
                ref={ref => inputRef = ref}
            />
        </div>

    )
};

export default Autocomplete;