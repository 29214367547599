import React, { useState, useEffect } from 'react';
import { getEvents } from '../services';
import { createUseStyles } from "react-jss";
import closeIcon from '../assets/close.png';
import  premierIcon from '../assets/premier_league.png';
import  laLigaIcon from '../assets/laliga_santander.png';
import  ligaMxIcon from '../assets/liga_bbva.png';

const useStyles = createUseStyles({
    blackboardContainer: {
        // position: "absolute",
        // top: 70,
        // left: 10,
        // width: 350,
        // borderRadius: 10,
        // boxShadow: "0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
        // overflow: "hidden",
        paddingBottom: 5,
        fontSize: 13
    },
    // closeBlackboard: {
    //     position: "relative",
    //     left: "calc(100% - 20px)",
    //     width: 20,
    //     height: 20,
    //     display: "inline-block",
    //     background: `url(${closeIcon})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     cursor: "pointer"
    // },
    headers: {
        display: "flex",
        fontWeight: 600,
        background: "#299AF3",
        padding: [5, 10],

        "& span": {
            flexGrow: 1,
            flexBasis: 0 
        }
    },
    headerDay: {
        width: "100%",
        background: "#F1F3F4",
        padding: [3, 10],
        boxSizing: "border-box",
        textAlign: "center",
        fontWeight: 600
    },
    blackboardItem: {
        display: "flex",
        padding: [2, 10],
        background: "white",
        "& span": {
            flexGrow:1,
            flexBasis: 0,
            display: "block",
            "&:last-of-type": {
                paddingBottom: 5
            }
        }
    },
    icons: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    icon: {
        width: "80px"
    }
})

const BlackboardList = (props) => {

    const {id, handleBlackboard} = props;

    const [events, setEvents] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getEvents(id).then(response => {
            const days = {};
            response.map((element) => {
                if (days[element.fecha] === undefined) {
                    days[element.fecha] = [element];
                } else {
                    days[element.fecha].push(element);
                }
            })
            setEvents(days);
        })
    },[id]);

    const getDaysContent  = () => {
        let content = [];
        for (const day in events) {
            content.push(<div className={classes.headerDay} key={day}>{day}</div>);
            content.push(
                events[day].map(({nombre, canal, fecha, hora, estado}, index) => (
                    <div className={classes.blackboardItem} key={`${day}-${index}`}>
                        <span>{nombre}</span>
                        <span>{canal}</span>
                        <span>{hora}</span>
                        <span>{estado}</span>
                    </div>
                ))
            );
        }
        return content;
    }

    return(
        <div className={classes.blackboardContainer}>
            <div className={classes.icons}>
                <img src={premierIcon} className={classes.icon}/>
                <img src={laLigaIcon} className={classes.icon}/>
                {/* <img src={ligaMxIcon} className={classes.icon}/> */}
            </div>
            {/* <div className={classes.closeBlackboard} onClick={ () => handleBlackboard(null)}></div> */}
            {/* <div className={classes.headers}>
                <span>Evento</span>
                <span>Canal</span>
                <span>Hora</span>
                <span>Estado</span>
            </div>
            {getDaysContent()} */}
        </div>
    );
}

export default BlackboardList;